<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Dokumen</h4> 
            <a href="#/dokumen/add" class="float-right btn btn-success form-control-sm">Tambah Data Baru</a>

          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteDokumen }">
          <thead>
            <tr>
              <th style="width:30px">No</th>
              <th>Dokumen</th>
              <th>Category</th>
              <th>File</th>
              <th style="width:50px">Edit</th>
              <th style="width:50px">Hapus</th>
            </tr>
          </thead>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteDokumen()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();

    let us = JSON.parse(this.$store.state.users);
      // alert(us.name);
      if(us.level=='1'){
        this.add= true;
      }
  },
  data: function() {
        return {
            deleteModal : false,
            idselected:"0",
            products:[],
            // add:false,
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },

    deleteDokumen: function(){
      axios.delete(process.env.VUE_APP_BASE_URL+"dokumen/"+this.idselected)
        .then((response) => {
            
            if(response.status==200){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }
            
        })
    },
    loadData: function() {
               
        //   axios
        // .post(
        //   process.env.VUE_APP_BASE_URL+"api/public/index.php/dokumen/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",false
        // )
        // .then((response) => {
        //   this.items=response.data.data;
        //   this.itemlengths = this.items.length;

        //   // alert(JSON.stringify(this.items));
        // });

      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"dokumen/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no", class:"text-center"},
                  { "data": "name" },                  
                  { "data": "category", class:"text-center"},
                  { "data": "file"},
                  { "data": "edit", class:"text-center" },
                  { 
                    "data": 'id_dokumen', 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                        return `<button @click.prevent="deleteDokumen('${data}')" 
                                        data-id="${data}" 
                                        class="btn btn-sm btn-danger btnhapus btn2">Hapus</button>`;
                    }
                    },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        // alert(process.env.VUE_APP_BASE_URL);
       const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
              "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"dokumen/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no", class:"text-center"},
                  { "data": "name" },                  
                  { "data": "category", class:"text-center"},
                  { "data": "file"},
                  { "data": "edit", class:"text-center"},
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                      return `<button @click.prevent="asdasdas(${row.id_dokumen})" 
                                      data-id="${data}" 
                                      class="btn btn-sm btn-danger btnhapus btn2">Hapus</button>`;
                  }
                }
              ]  
           
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';

          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id_dokumen);
              self.confirmDelete(objdata.id_dokumen)
          });
        // })
      }
      
    }
  }
}
</script>